import {DocumentTemplateColumn, DocumentTemplateColumnInterface} from "./documentTemplateColumn";

export interface DocumentTemplateRowInterface {
    id?: string;
    order: number;
    columns?: DocumentTemplateColumnInterface[];
    document_template_columns_attributes?: DocumentTemplateColumnInterface[];
}

export class DocumentTemplateRow implements DocumentTemplateRowInterface {
    id: string;
    order: number;
    columns: DocumentTemplateColumn[];

    constructor(data: Partial<DocumentTemplateRowInterface>) {
        this.id = data.id!;
        this.order = data.order!;
        this.columns = data.columns?.map((columnData) => new DocumentTemplateColumn(columnData)) || [];
    }

    toJson(): DocumentTemplateRowInterface {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            order: this.order,
            document_template_columns_attributes: this.columns.map(column => column.toJson()),
        };
    }
}
