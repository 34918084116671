import React from 'react';
import {
    DateInputElement,
    DocumentTemplateElement,
    MultiLineTextInputElement,
    TextInputElement,
    YesNoInputElement,
    PhoneNumberInputElement,
    DropdownElement,
    SsnInputElement,
    EmailInputElement
} from 'types/documentTemplateElement';
import TextInputProperties from './TextInputProperties';
import MultiLineTextInputProperties from "./MultiLineTextInputProperties";
import DropdownProperties from "./DropdownProperties";
import PhoneNumberInputProperties from "./PhoneNumberInputProperties";
import YesNoInputProperties from "./YesNoInputProperties";
import DateInputProperties from "./DateInputProperties";
import SsnInputProperties from "./SsnInputPropreties";
import EmailInputProperties from "./EmailInputProperties";


interface RenderElementPropertiesProps {
    element: DocumentTemplateElement;
    updateElement: (updatedElement: DocumentTemplateElement) => void;
}

const RenderElementProperties: React.FC<RenderElementPropertiesProps> = ({
                                                                             element,
                                                                             updateElement,
                                                                         }) => {
    switch (element.elementType) {
        case 'TextInput':
            return (
                <TextInputProperties
                    element={element as TextInputElement}
                    updateElement={updateElement}
                />
            );
        case "MultiLineTextInput":
            return (
                <MultiLineTextInputProperties
                    element={element as MultiLineTextInputElement}
                    updateElement={updateElement}
                />
            );
        case "Dropdown":
            return (
                <DropdownProperties
                    element={element as DropdownElement}
                    updateElement={updateElement}
                />
            );
        case "PhoneNumberInput":
            return (
                <PhoneNumberInputProperties
                    element={element as PhoneNumberInputElement}
                    updateElement={updateElement}
                />
            );
        case 'YesNoInput':
            return (
                <YesNoInputProperties
                    element={element as YesNoInputElement}
                    updateElement={updateElement}
                />
            );
            case 'SsnInput':
                return (
                    <SsnInputProperties
                        element={element as SsnInputElement}
                        updateElement={updateElement}
                    />
                );
            case 'DateInput':
            return (
                <DateInputProperties
                    element={element as DateInputElement}
                    updateElement={updateElement}
                />
            );
            case 'EmailInput':
            return (
                <EmailInputProperties
                    element={element as EmailInputElement}
                    updateElement={updateElement}
                />
            );


        default:
            return (
                <p className="text-slate-400 text-sm font-medium">
                    This element doesn't have any properties
                </p>
            )
    }
};

export default RenderElementProperties;
