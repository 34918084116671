import React from 'react';
import {ElementProps, EmailInputElement, EmailInputElementClass} from "types/documentTemplateElement";
import TextInput from "../inputs/TextInput";

interface EmailInputElementProps extends ElementProps<EmailInputElementClass> {}

const EmailInputElementComponent: React.FC<EmailInputElementProps> = ({ element, updateElement }) => {
    return (
        <div className="w-full m-8">
            <TextInput
                id={element.id}
                type="text"
                label={element.properties.showLabel ? element.properties.label : ""}
                value=""
                placeholder={element.properties.placeholder}
                required={element.properties.required}
                onChange={() => {}}
            />
        </div>
    );
};

export default EmailInputElementComponent;
