export interface HrDocumentInterface {
    id: string;
    name: string;
    description: string;
    status?: string;
    requireIssueDate?: boolean;
    requireExpirationDate?: boolean;
}

export class HrDocument implements HrDocumentInterface {
    id: string;
    name: string;
    description: string;
    status?: any;
    requireIssueDate?: boolean;
    requireExpirationDate?: boolean;

    constructor(data: Partial<HrDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.description = data.description!;
        this.status = data.status || 'active';
        this.requireIssueDate = data.requireIssueDate || false;
        this.requireExpirationDate = data.requireExpirationDate || false;
    }

    toJson(): HrDocumentInterface {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            status: this.status.toLowerCase(),
            requireIssueDate: this.requireIssueDate,
            requireExpirationDate: this.requireExpirationDate,
        };
    }
}
