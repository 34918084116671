import React from 'react';
import {
    DocumentElement,
    DropdownElement,
    MultiLineTextInputElement,
    TextBlockElement,
    TextInputElement,
    PhoneNumberInputElement,
    YesNoInputElement,
    DateInputElement,
    SsnInputElement,
    TextBlockElementClass,
    TextInputElementClass,
    MultiLineTextInputElementClass,
    PhoneNumberInputElementClass,
    YesNoInputElementClass,
    DateInputElementClass,
    DropdownElementClass,
    SsnInputElementClass, EmailInputElementClass, EmailInputElement,
} from 'types/documentTemplateElement';
import TextInputElementComponent from "./TextInputElementComponent";
import TextBlockElementComponent from "./TextBlockElementComponent";
import MultiLineTextInputElementComponent from "./MultiLineTextInputElementComponent";
import DropdownElementComponent from "./DropdownElementComponent";
import PhoneNumberInputElementComponent from "./PhoneNumberInputElementComponent";
import YesNoInputElementComponent from "./YesNoInputElementComponent";
import DateInputElementComponent from "./DateInputElementComponent";
import SsnInputElementComponent from "./SsnInputElementComponent";
import EmailInputElementComponent from "./EmailInputElementComponent";

interface RenderElementProps {
    element: DocumentElement;
    updateElement: (updatedElement: DocumentElement) => void;
}

const RenderElement: React.FC<RenderElementProps> = ({element, updateElement}) => {
    switch (element.elementType) {
        case 'TextBlock':
            return (
                <TextBlockElementComponent
                    element={element as TextBlockElementClass}
                    updateElement={updateElement as (updatedElement: TextBlockElement) => void}
                />
            );
        case 'TextInput':
            return (
                <TextInputElementComponent
                    element={element as TextInputElementClass}
                    updateElement={updateElement as (updatedElement: TextInputElement) => void}
                />
            );
        case 'MultiLineTextInput':
            return (
                <MultiLineTextInputElementComponent
                    element={element as MultiLineTextInputElementClass}
                    updateElement={updateElement as (updatedElement: MultiLineTextInputElement) => void}
                />
            );
        case 'PhoneNumberInput':
            return (
                <PhoneNumberInputElementComponent
                    element={element as PhoneNumberInputElementClass}
                    updateElement={updateElement as (updatedElement: PhoneNumberInputElement) => void}
                />
            );

        case 'YesNoInput':
            return (
                <YesNoInputElementComponent
                    element={element as YesNoInputElementClass}
                    updateElement={updateElement as (updatedElement: YesNoInputElement) => void}
                />
            );
            case 'SsnInput':
            return (
                <SsnInputElementComponent
                    element={element as SsnInputElementClass}
                    updateElement={updateElement as (updatedElement: SsnInputElement) => void}
                />
            );

        case 'EmailInput':
            return (
                <EmailInputElementComponent
                    element={element as EmailInputElementClass}
                    updateElement={updateElement as (updatedElement: EmailInputElement) => void}
                />
            );

        case 'DateInput':
            return (
                <DateInputElementComponent
                    element={element as DateInputElementClass}
                    updateElement={updateElement as (updatedElement: DateInputElement) => void}
                />
            );

        case 'Dropdown':
            return <DropdownElementComponent
                element={element as DropdownElementClass}
                updateElement={updateElement as (updatedElement: DropdownElement) => void}
            />


        default:
            return null;
    }
};

export default RenderElement;
