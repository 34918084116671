import React from 'react';
import DateInput from 'components/inputs/DateInput';
import {ElementProps, DateInputElement, DateInputElementClass} from "types/documentTemplateElement";


interface DateInputElementProps extends ElementProps<DateInputElementClass> {}

const DateInputElementComponent: React.FC<DateInputElementProps> = ({ element, updateElement }) => {
    return (
        <div className="w-full m-8">
            <DateInput
                id={element.id}
                label={element.properties.showLabel ? element.properties.label : ""}
                type="date"
                value=""
                isClickDisabled={true}
                onChange={() => {}}
                required={element.properties.required}
                placeholder="MM/DD/YYYY"
                allowPastDates={element.properties.allowPastDates}
            />
        </div>
    );
};

export default DateInputElementComponent;
