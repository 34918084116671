import React from 'react';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import {ElementProps, PhoneNumberInputElementClass} from "types/documentTemplateElement";

interface PhoneNumberInputElementProps extends ElementProps<PhoneNumberInputElementClass> {}

const PhoneNumberInputElementComponent: React.FC<PhoneNumberInputElementProps> = ({ element, updateElement }) => {
    return (
        <div className="w-full m-8">
            <PhoneNumberInput
                id={element.id}
                label={element.properties.showLabel ? element.properties.label : ""}
                value=""
                placeholder={element.properties.placeholder}
                required={element.properties.required}
                onChange={() => {}}
                phoneNumber={element.properties.phoneNumber}
                setPhoneNumber={() => {}}
                isClickDisabled={true}

            />
        </div>
    );
};

export default PhoneNumberInputElementComponent;
