import {
    DocumentElementClass,
    DocumentElement,
    DateInputElement,
    DateInputElementClass,
    YesNoInputElement,
    YesNoInputElementClass,
    PhoneNumberInputElement,
    PhoneNumberInputElementClass,
    MultiLineTextInputElement,
    MultiLineTextInputElementClass,
    TextInputElement,
    TextInputElementClass,
    TextBlockElement,
    TextBlockElementClass,
    DropdownElement,
    DropdownElementClass
} from "./documentTemplateElement";

export interface DocumentTemplateColumnInterface {
    id?: string;
    element?: DocumentElement;
    document_template_element_attributes?: DocumentElement;
}

export class DocumentTemplateColumn implements DocumentTemplateColumnInterface {
    id: string;
    element?: DocumentElementClass;

    constructor(data: Partial<DocumentTemplateColumnInterface>) {
        this.id = data.id!;
        if (data.element) {
            this.element = this.createElementInstance(data.element);
        }
    }

    private createElementInstance(elementData: DocumentElement): DocumentElementClass {
        switch (elementData.elementType) {
            case 'Dropdown':
                return new DropdownElementClass(elementData as DropdownElement);
            case 'TextBlock':
                return new TextBlockElementClass(elementData as TextBlockElement);
            case 'TextInput':
                return new TextInputElementClass(elementData as TextInputElement);
            case 'MultiLineTextInput':
                return new MultiLineTextInputElementClass(elementData as MultiLineTextInputElement);
            case 'PhoneNumberInput':
                return new PhoneNumberInputElementClass(elementData as PhoneNumberInputElement);
            case 'YesNoInput':
                return new YesNoInputElementClass(elementData as YesNoInputElement);
            case 'DateInput':
                return new DateInputElementClass(elementData as DateInputElement);
            default:
                throw new Error(`Unsupported element type: ${elementData}`);
        }
    }

    toJson(): DocumentTemplateColumnInterface {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            document_template_element_attributes: this.element ? this.element.toJson() : undefined,
        };
    }
}
