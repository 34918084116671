// documentTemplateElement.ts

export type DocumentElementType =
    | 'TextBlock'
    | 'TextInput'
    | 'MultiLineTextInput'
    | 'Dropdown'
    | 'YesNoInput'
    | 'DateInput'
    | 'PhoneNumberInput'
    | 'Table'
    | 'SsnInput'
    | 'EmailInput';

export interface DocumentTemplateElement {
    id?: string;
    elementType: DocumentElementType;
    properties: any; // Las propiedades específicas se definirán en cada elemento
}
export interface ElementProps<T extends DocumentElementClass> {
    element: T;
    updateElement: (updatedElement: T) => void;
}
// Interfaces de propiedades para cada elemento

export interface DropdownElementProperties {
    label: string;
    isMulti: boolean;
    required: boolean;
    options: string[];
    showLabel: boolean;
}

export interface TextBlockElementProperties {
    content: string;
}

export interface TextInputElementProperties {
    label: string;
    placeholder?: string;
    required: boolean;
    showLabel: boolean;
}

export interface MultiLineTextInputElementProperties {
    label: string;
    placeholder?: string;
    required: boolean;
    showLabel: boolean;
}

export interface PhoneNumberInputElementProperties {
    label: string;
    placeholder?: string;
    required: boolean;
    showLabel: boolean;
    phoneNumber: string;
}

export interface YesNoInputElementProperties {
    label: string;
    required: boolean;
    showLabel: boolean;
}

export interface DateInputElementProperties {
    label: string;
    required: boolean;
    showLabel: boolean;
    placeholder: string;
    allowPastDates: boolean;
}

export interface SsnInputElementProperties {
    label: string;
    required: boolean;
    showLabel: boolean;
}

export interface EmailInputElementProperties {
    label: string;
    required: boolean;
    showLabel: boolean;
    placeholder?: string;
}
// Interfaces para cada tipo de elemento

export interface DropdownElement extends DocumentTemplateElement {
    elementType: 'Dropdown';
    properties: DropdownElementProperties;
}

export interface TextBlockElement extends DocumentTemplateElement {
    elementType: 'TextBlock';
    properties: TextBlockElementProperties;
}

export interface TextInputElement extends DocumentTemplateElement {
    elementType: 'TextInput';
    properties: TextInputElementProperties;
}

export interface MultiLineTextInputElement extends DocumentTemplateElement {
    elementType: 'MultiLineTextInput';
    properties: MultiLineTextInputElementProperties;
}

export interface PhoneNumberInputElement extends DocumentTemplateElement {
    elementType: 'PhoneNumberInput';
    properties: PhoneNumberInputElementProperties;
}

export interface YesNoInputElement extends DocumentTemplateElement {
    elementType: 'YesNoInput';
    properties: YesNoInputElementProperties;
}

export interface DateInputElement extends DocumentTemplateElement {
    elementType: 'DateInput';
    properties: DateInputElementProperties;
}
export interface SsnInputElement extends DocumentTemplateElement {
    elementType: 'SsnInput';
    properties: SsnInputElementProperties;
}
export interface EmailInputElement extends DocumentTemplateElement {
    elementType: 'EmailInput';
    properties: EmailInputElementProperties;
}

export class DropdownElementClass implements DropdownElement {
    id: string;
    elementType: 'Dropdown';
    properties: DropdownElementProperties;

    constructor(data: DropdownElement) {
        this.id = data.id!;
        this.elementType = 'Dropdown';
        this.properties = {
            label: data.properties.label,
            isMulti: data.properties.isMulti,
            required: data.properties.required,
            options: data.properties.options,
            showLabel: data.properties.showLabel,
        };
    }

    toJson(): DropdownElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export class TextBlockElementClass implements TextBlockElement {
    id: string;
    elementType: 'TextBlock';
    properties: TextBlockElementProperties;

    constructor(data: TextBlockElement) {
        this.id = data.id!;
        this.elementType = 'TextBlock';
        this.properties = {
            content: data.properties.content,
        };
    }

    toJson(): TextBlockElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export class TextInputElementClass implements TextInputElement {
    id: string;
    elementType: 'TextInput';
    properties: TextInputElementProperties;

    constructor(data: TextInputElement) {
        this.id = data.id!;
        this.elementType = 'TextInput';
        this.properties = {
            label: data.properties.label,
            placeholder: data.properties.placeholder,
            required: data.properties.required,
            showLabel: data.properties.showLabel,
        };
    }

    toJson(): TextInputElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export class MultiLineTextInputElementClass implements MultiLineTextInputElement {
    id: string;
    elementType: 'MultiLineTextInput';
    properties: MultiLineTextInputElementProperties;

    constructor(data: MultiLineTextInputElement) {
        this.id = data.id!;
        this.elementType = 'MultiLineTextInput';
        this.properties = {
            label: data.properties.label,
            placeholder: data.properties.placeholder,
            required: data.properties.required,
            showLabel: data.properties.showLabel,
        };
    }

    toJson(): MultiLineTextInputElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export class PhoneNumberInputElementClass implements PhoneNumberInputElement {
    id: string;
    elementType: 'PhoneNumberInput';
    properties: PhoneNumberInputElementProperties;

    constructor(data: PhoneNumberInputElement) {
        this.id = data.id!;
        this.elementType = 'PhoneNumberInput';
        this.properties = {
            label: data.properties.label,
            placeholder: data.properties.placeholder,
            required: data.properties.required,
            showLabel: data.properties.showLabel,
            phoneNumber: data.properties.phoneNumber,
        };
    }

    toJson(): PhoneNumberInputElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export class YesNoInputElementClass implements YesNoInputElement {
    id: string;
    elementType: 'YesNoInput';
    properties: YesNoInputElementProperties;

    constructor(data: YesNoInputElement) {
        this.id = data.id!;
        this.elementType = 'YesNoInput';
        this.properties = {
            label: data.properties.label,
            required: data.properties.required,
            showLabel: data.properties.showLabel,
        };
    }

    toJson(): YesNoInputElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export class SsnInputElementClass implements SsnInputElement {
    id: string;
    elementType: 'SsnInput';
    properties: SsnInputElementProperties;

    constructor(data: SsnInputElement) {
        this.id = data.id!;
        this.elementType = 'SsnInput';
        this.properties = {
            label: data.properties.label,
            required: data.properties.required,
            showLabel: data.properties.showLabel,
        };
    }

    toJson(): SsnInputElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export class DateInputElementClass implements DateInputElement {
    id: string;
    elementType: 'DateInput';
    properties: DateInputElementProperties;

    constructor(data: DateInputElement) {
        this.id = data.id!;
        this.elementType = 'DateInput';
        this.properties = {
            label: data.properties.label,
            required: data.properties.required,
            showLabel: data.properties.showLabel,
            placeholder: data.properties.placeholder,
            allowPastDates: data.properties.allowPastDates,
        };
    }

    toJson(): DateInputElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export class EmailInputElementClass implements EmailInputElement {
    id: string;
    elementType: 'EmailInput';
    properties: EmailInputElementProperties;

    constructor(data: EmailInputElement) {
        this.id = data.id!;
        this.elementType = 'EmailInput';
        this.properties = {
            label: data.properties.label,
            required: data.properties.required,
            showLabel: data.properties.showLabel,
            placeholder: data.properties.placeholder,
        };
    }

    toJson(): EmailInputElement {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            elementType: this.elementType,
            properties: this.properties,
        };
    }
}

export type DocumentElementClass =
    | DropdownElementClass
    | TextBlockElementClass
    | TextInputElementClass
    | MultiLineTextInputElementClass
    | PhoneNumberInputElementClass
    | YesNoInputElementClass
    | DateInputElementClass
    | SsnInputElementClass
    | EmailInputElementClass;


export type DocumentElement =
    | DropdownElement
    | TextBlockElement
    | TextInputElement
    | MultiLineTextInputElement
    | PhoneNumberInputElement
    | YesNoInputElement
    | DateInputElement
    | SsnInputElement
    | EmailInputElement;



